/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    code: "code",
    hr: "hr"
  }, _provideComponents(), props.components), {Type} = _components;
  if (!Type) _missingMdxReference("Type", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "overview",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#overview",
    "aria-label": "overview permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Overview"), "\n", React.createElement(_components.p, null, "The Elements component collects elements provided by HTML and initializes them."), "\n", React.createElement(_components.h2, {
    id: "properties",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#properties",
    "aria-label": "properties permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Properties"), "\n", React.createElement(_components.h3, {
    id: "root",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#root",
    "aria-label": "root permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "root")), "\n", React.createElement(Type, {
    code: 'root: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "The root element."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "track",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#track",
    "aria-label": "track permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "track")), "\n", React.createElement(Type, {
    code: 'track: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "The track element."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "list",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#list",
    "aria-label": "list permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "list")), "\n", React.createElement(Type, {
    code: 'list: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "The list element."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "slides",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#slides",
    "aria-label": "slides permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "slides")), "\n", React.createElement(Type, {
    code: 'slides: HTMLElement[]'
  }), "\n", React.createElement(_components.p, null, "An array with slide elements."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "arrows",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#arrows",
    "aria-label": "arrows permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "arrows")), "\n", React.createElement(Type, {
    code: 'arrows: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "The element that wraps previous and next arrows. This can be ", React.createElement(_components.code, null, "undefined"), "."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "prev",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#prev",
    "aria-label": "prev permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "prev")), "\n", React.createElement(Type, {
    code: 'prev: HTMLButtonElement'
  }), "\n", React.createElement(_components.p, null, "The previous arrow element. This can be ", React.createElement(_components.code, null, "undefined"), ".\nBe aware that this does not hold the generated arrow. Use ", React.createElement(_components.code, null, "Arrow#arrows"), " instead."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "next",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#next",
    "aria-label": "next permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "next")), "\n", React.createElement(Type, {
    code: 'slider: HTMLButtonElement'
  }), "\n", React.createElement(_components.p, null, "The next arrow element. This can be ", React.createElement(_components.code, null, "undefined"), ".\nBe aware that this does not hold the generated arrow. Use ", React.createElement(_components.code, null, "Arrow#arrows"), " instead."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "bar",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bar",
    "aria-label": "bar permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "bar")), "\n", React.createElement(Type, {
    code: 'slider: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "The progress bar element for autoplay. This can be ", React.createElement(_components.code, null, "undefined"), "."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "toggle",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#toggle",
    "aria-label": "toggle permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "toggle")), "\n", React.createElement(Type, {
    code: 'toggle: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "The toggle button for autoplay. This can be ", React.createElement(_components.code, null, "undefined"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
